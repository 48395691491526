import React from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';

import Profile from './Profile';
import WebSites from './WebSites';

import MorlandIcon from './assets/Morland';
import WebIcon from './assets/Web';
import GamesIcon from './assets/Games';
import Games from './Games';
import Game from './Game';
import WebSite from './WebSite';

export default function App() {
	let { pathname } = useLocation();

	return (
		<React.Fragment>
			<Routes>
				<Route index element={<Profile />} />
				<Route path='web' element={<WebSites />} />
				<Route path='web/:slug' element={<WebSite />} />
				<Route path='games' element={<Games />} />
				<Route path='games/:slug' element={<Game />} />
			</Routes>
			<nav id='menu'>
				<ul>
					<li><Link to='/'><MorlandIcon active={pathname === '/'} /></Link></li>
					<li><Link to='/web'><WebIcon active={pathname === '/web'} /></Link></li>
					<li><Link to='/games'><GamesIcon active={pathname === '/games'} /></Link></li>
				</ul>
			</nav>
		</React.Fragment>
	);
}