import Corridor from './assets/corridor.png';
import Spell from './assets/spell.png';
import Slot from './assets/slot.png';
import SXC from './assets/sxc.png';
import House from './assets/house.png';
import THG from './assets/thg.png';
import Eternal from './assets/eternal.png';
import TMRGTF from './assets/tmrgtf.png';
import Snail from './assets/snail.png';
import Tap from './assets/tap.png';
import Gaben from './assets/gaben.png';
import Laivasota from './assets/laivasota.png';

import Hub from './assets/hub.png';
import RE from './assets/re.png';
import Aimo from './assets/aimo.png';
import Mistpipe from './assets/mistpipe.png';
import NP from './assets/np.png';

const ProjectType = {
    GameJamProject: "Game jam project",
    PersonalProject: "Personal project",
    SchoolProject: "School project",
}

const Tech = {
    CoolBasic: "CoolBasic",
    Csharp: "C#",
    Godot: "Godot",
    Jypeli: "Jypeli",
    MongoDB: "MongoDB",
    MySQL: "MySQL",
    Node: "Node.js",
    PHP: "PHP",
    Python: "Python",
    React: "React",
    Redis: "Redis",
    Strapi: "Strapi",
    Unity: "Unity",
}

const Platform = {
    Android: "Android",
    Windows: "Windows",
}

export const projects = {
    games: [
        {
            slug: "corridor-of-despair-and-hope",
            name: "Corridor of Despair and Hope",
            type: ProjectType.SchoolProject,
            year: 2021,
            team: 1,
            description: "You are trapped inside the Corridor of Despair and Hope.",
            tech: [Tech.Godot],
            img: Corridor,
            link: "https://grlgameproject.wordpress.com/corridor-of-despair-and-hope/",
        },
        {
            slug: "spell-yeller",
            name: "Spell Yeller",
            type: ProjectType.PersonalProject,
            year: 2019,
            team: 1,
            description: "Epic wizard duel! Yell spells and defeat your opponent.",
            tech: [Tech.React, Tech.Node],
            img: Spell,
            link: "https://sy.morland.red",
            portrait: true,
        },
        {
            slug: "slot-hero",
            name: "Slot Hero",
            type: ProjectType.GameJamProject,
            year: 2018,
            team: 2,
            description: "A brave hero embarks to an adventure with his trusty slot machine on his back. In order to defeat obstacles he must spin the machine and get a random set of skills. Every encounter will be different.",
            tech: [Tech.Unity],
            img: Slot,
            link: "https://morland.itch.io/slot-hero",
        },
        {
            slug: "shape-x-color",
            name: "SHAPExCOLOR",
            type: ProjectType.PersonalProject,
            year: 2016,
            team: 1,
            description: "Memorize sequences of shapes and colors. Keep dementia away.",
            tech: [Tech.Unity],
            img: SXC,
            download: [
                {
                    platform: Platform.Android,
                    link: `${process.env.PUBLIC_URL}/downloads/SXC_1.2.apk`,
                },
            ],
        },
        {
            slug: "a-story-of-a-man-whose-house-burned-down",
            name: "A Story of a Man Whose House Burned Down",
            type: ProjectType.GameJamProject,
            year: 2016,
            team: 1,
            description: "A little RPG of a man rebuilding his house after it was burned down in the middle of the night.",
            tech: [Tech.Unity],
            img: House,
            link: "https://morland.itch.io/a-sroty-of-a-man-whose-house-burned-down",
        },
        {
            slug: "trading-hero-game",
            name: "Trading Hero Game",
            type: ProjectType.PersonalProject,
            year: 2016,
            team: 1,
            description: "Collect heroes, send them to battle and form the ultimate party. Unfortunately I never managed to get the titular trading to work...",
            tech: [Tech.Unity],
            img: THG,
            download: [
                {
                    platform: "Android",
                    link: `${process.env.PUBLIC_URL}/downloads/THG.apk`,
                },
            ],
            portrait: true,
        },
        {
            slug: "eternal-warrior",
            name: "Eternal Warrior",
            type: ProjectType.SchoolProject,
            year: 2016,
            team: 2,
            description: "Survive the zombie apocalypse by clearing and holding helipads. How long can you keep up? Requires Microsoft XNA Framework Redistributable 4.0.",
            tech: [Tech.Jypeli,  Tech.Csharp],
            img: Eternal,
            download: [
                {
                    platform: Platform.Windows,
                    link: `${process.env.PUBLIC_URL}/downloads/EternalWarrior.zip`,
                },
            ],
        },
        {
            slug: "the-most-random-geme-this-far",
            name: "The Most Random Game This Far",
            type: ProjectType.PersonalProject,
            year: 2015,
            team: 1,
            description: "A fun little side-scroller I never got to release because I didn't know how to backup properly back then.",
            tech: [Tech.Unity],
            img: TMRGTF,
            download: [
                {
                    platform: Platform.Android,
                    link: `${process.env.PUBLIC_URL}/downloads/TMRGTF.apk`,
                },
            ],
        },
        {
            slug: "snail-race",
            name: "Snail Race",
            type: ProjectType.PersonalProject,
            year: 2014,
            team: 1,
            description: "Cheer your snail to victory! The game is more fun the more you yell!",
            tech: [Tech.Unity],
            img: Snail,
            download: [
                {
                    platform: Platform.Android,
                    link: `${process.env.PUBLIC_URL}/downloads/SnailRace.apk`,
                },
            ],
        },
        {
            slug: "tap-it",
            name: "TapIt",
            type: ProjectType.PersonalProject,
            year: 2014,
            team: 1,
            description: "Tap the green circles as fast as you can. My first game released in Google Play Store, unfortunately no-longer availabe from there.",
            tech: [Tech.Unity],
            img: Tap,
            download: [
                {
                    platform: "Android",
                    link: `${process.env.PUBLIC_URL}/downloads/TapIt.apk`,
                },
            ],
            portrait: true,
        },
        {
            slug: "gaben",
            name: "Gaben",
            type: ProjectType.PersonalProject,
            year: 2013,
            team: 1,
            description: "One of my first games with graphics. Steam vs Origin was a big thing at the time of release.",
            tech: [Tech.CoolBasic],
            img: Gaben,
            download: [
                {
                    platform: Platform.Windows,
                    link: `${process.env.PUBLIC_URL}/downloads/Gaben 1.0.zip`,
                },
            ],
        },
        {
            slug: "laivasota",
            name: "Laivasota",
            type: ProjectType.PersonalProject,
            year: 2012,
            team: 1,
            description: "The very first game I've released. It's not much of a game but surely ignited the spark within me.",
            tech: [Tech.Python],
            img: Laivasota,
            download: [
                {
                    platform: Platform.Windows,
                    link: `${process.env.PUBLIC_URL}/downloads/Laivasota.zip`,
                },
            ],
        },
    ],
    web: [
        {
            slug: "hub",
            name: "Hub",
            type: ProjectType.SchoolProject,
            year: 2021,
            team: 7,
            description: "A hub for finding collegues with specific proficiencies. I was in charge of UI design and some front-end programming.",
            tech: [Tech.React, Tech.Strapi, Tech.MySQL],
            img: Hub,
            github: "https://github.com/SirMorland/huld-hub",
        },
        {
            slug: "regularity-equipment",
            name: "Regularity Equipment",
            type: ProjectType.PersonalProject,
            year: 2021,
            team: 1,
            description: "An equipment for exploring regularities of NFT-tokens.",
            tech: [Tech.React, Tech.Node, Tech.MongoDB],
            img: RE,
            link: "https://regularity.equipment",
        },
        {
            slug: "aimo",
            name: "Aimo",
            type: ProjectType.PersonalProject,
            year: 2020,
            team: 1,
            description: "Track your spendings and budget across different product/service categories.",
            tech: [Tech.React, Tech.Node, Tech.MongoDB],
            img: Aimo,
            link: "https://dev.morland.red/aimo",
            github: "https://github.com/SirMorland/Aimo",
        },
        {
            slug: "mistpipe",
            name: "Mistpipe",
            type: ProjectType.PersonalProject,
            year: 2019,
            team: 1,
            description: "A multiuser YouTube playlist for LAN-parties.",
            tech: [Tech.React, Tech.Node],
            img: Mistpipe,
            link: "https://dev.morland.red/mistpipe",
            github: "https://github.com/SirMorland/mistpipe",
        },
        {
            slug: "nano-pages",
            name: "Nano Pages",
            type: ProjectType.PersonalProject,
            year: 2019,
            team: 1,
            description: "A social media platform with focus on longer posts or \"pages\" as they are called here.",
            tech: [Tech.React, Tech.PHP, Tech.Redis],
            img: NP,
            link: "https://np.morland.red/",
            github: "https://github.com/SirMorland/nano-pages-react",
        },
    ],
}