import { useParams } from "react-router-dom";
import { projects } from "./Data";
import Project from "./Project";

export default function WebSite() {
    const { slug } = useParams();
    
    const web = projects.web.find(a => a.slug === slug);

	return (
        <Project project={web} />
    );
}