import React from 'react';
import { Link } from 'react-router-dom';
import { projects } from './Data';

export default function WebSites() {
	return (
		<main>
			<h1>Web</h1>
			<div className='grid'>
				{projects.web.map((web, index) =>
					<div className={`card${web.portrait ? ' tall' : ""}`} key={index}>
						<Link to={web.slug} className='picture'>
							<img src={web.img} />
						</Link>
					</div>
				)}
			</div>
		</main>
	);
}