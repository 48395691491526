import { useParams } from "react-router-dom";
import { projects } from "./Data";
import Project from "./Project";

export default function Game() {
    const { slug } = useParams();
    
    const game = projects.games.find(a => a.slug === slug);

	return (
        <Project project={game} />
    );
}