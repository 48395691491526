import React from 'react';
import { Link } from 'react-router-dom';
import { projects } from './Data';

export default function Games() {
	return (
		<main>
			<h1>Games</h1>
			<div className='grid'>
				{projects.games.map((game, index) =>
					<div className={`card${game.portrait ? ' tall' : ""}`} key={index}>
						<Link to={game.slug} className='picture'>
							<img src={game.img} />
						</Link>
					</div>
				)}
			</div>
		</main>
	);
}