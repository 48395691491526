export default function Web ({ active }) {
	return (
		<svg width={24} height={24} viewBox='0 0 24 24'>
			<path d='M 15 0 C 21 0 24 3 24 9 L 24 15 C 24 21 21 24 15 24 L 9 24 C 3 24 0 21 0 15 L 0 9 C 0 3 3 0 9 0 Z'
				style={{stroke: active ? 'transparent' : 'var(--header-text-color-hsl)', strokeWidth: 2, fill: active ? 'var(--header-text-color-hsl)' : 'transparent'}} />
			<path d='M 4 4 L 6 8 L 8 4 L 10 8 L 12 4'
				style={{stroke: active ? 'var(--main-color-hsl)' : 'var(--header-text-color-hsl)', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round', fill: 'none'}} />
			<path d='M 8 10 L 10 14 L 12 10 L 14 14 L 16 10'
				style={{stroke: active ? 'var(--main-color-hsl)' : 'var(--header-text-color-hsl)', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round', fill: 'none'}} />
			<path d='M 12 16 L 14 20 L 16 16 L 18 20 L 20 16'
				style={{stroke: active ? 'var(--main-color-hsl)' : 'var(--header-text-color-hsl)', strokeWidth: 2, strokeLinecap: 'round', strokeLinejoin: 'round', fill: 'none'}} />
		</svg>
	);
};