import Person from "./assets/Person";

export default function Project({project}) {
    if (!project) {
        return (
            <main>
                <h1>404</h1>
            </main>
        );
    }

	return (
		<main>
			<h1>{project.name}</h1>
			<div className='grid'>
                <div className={`card${project.portrait ? ' tall' : ""}`}>
                    <a href={project.img} rel="noopener noreferrer" target="_blank" className='picture'>
                        <img src={project.img} />
                    </a>
                    <h4>{project.type} • {project.year} • {project.team} <Person /></h4>
                    <p>{project.description}</p>
                    <p>{project.tech.join(" • ")}</p>
                    {project.link && <a href={project.link} rel="noopener noreferrer" target="_blank">{project.link}</a>}
                    {project.download && project.download.map(({platform, link}, index) =>
                        <a href={link} download key={index}>Download for {platform}</a>
                    )}
                    {project.github && <a href={project.github} rel="noopener noreferrer" target="_blank">{project.github}</a>}
                </div>
            </div>
        </main>
    );
}