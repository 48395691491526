import React from 'react';

import Ville from './assets/Ville.jpg';

export default function Profile() {
	return (
		<React.Fragment>
			<main>
				<div id='profile-picture'>
					<img src={Ville} />
				</div>
				
				<h1>Ville Helppolainen</h1>
				<p>A software developer, a photographer, and a synth nerd. Also dabbling my feets in parenting.</p>
				
				<h2>Brief</h2>
				<ul>
					<li>27 years old</li>
					<li>Married</li>
					<li>Two children</li>
					<li>Tampere, Finland</li>
					<li>Bachelor of Science from IT</li>
					<li>4 years as software developer</li>
				</ul>

				<h2>Biz</h2>
				<p>Interested in hiring me? I'm currently focusing to my master studies in Human-Technology Interactions, but international companies looking for a skilled front-end developer/designer, slide into DMs ;)</p>
			</main>

			<footer>
				<h2>Contact me</h2>
				<div className="links">
					<a href="mailto:ville.helppolainen@gmail.com" rel="noopener noreferrer" target="_blank"><img src={`${process.env.PUBLIC_URL}/email.svg`} alt="Email" /></a>
					<a href="https://www.linkedin.com/in/ville-helppolainen/" rel="noopener noreferrer" target="_blank"><img src={`${process.env.PUBLIC_URL}/in.svg`} alt="LinkedIn" /></a>
					<a href="https://github.com/SirMorland" rel="noopener noreferrer" target="_blank"><img src={`${process.env.PUBLIC_URL}/git.svg`} alt="GitHub" /></a>
					<a href="https://www.facebook.com/sirmorland" rel="noopener noreferrer" target="_blank"><img src={`${process.env.PUBLIC_URL}/facebook.svg`} alt="Facebook" /></a>
					<a href="https://www.instagram.com/sirmorland/" rel="noopener noreferrer" target="_blank"><img src={`${process.env.PUBLIC_URL}/ig.svg`} alt="Instagram" /></a>
					<a href="https://twitter.com/SirMorland" rel="noopener noreferrer" target="_blank"><img src={`${process.env.PUBLIC_URL}/twitter.svg`} alt="Twitter" /></a>
					<a href="https://www.youtube.com/channel/UC8ABL-o4LGDfbHi5nYKsy-w" rel="noopener noreferrer" target="_blank"><img src={`${process.env.PUBLIC_URL}/youtube.svg`} alt="YouTube" /></a>
					<a href="https://soundcloud.com/sir-morland" rel="noopener noreferrer" target="_blank"><img src={`${process.env.PUBLIC_URL}/sc.svg`} alt="SoundCloud" /></a>
					<a href="https://np.morland.red/Morland" rel="noopener noreferrer" target="_blank"><img src={`${process.env.PUBLIC_URL}/np.svg`} alt="Nano Pages" /></a>
				</div>
			</footer>
		</React.Fragment>
	);
}